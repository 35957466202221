<template>
  <div class="file-upload-container">
    <div
      ref="dropbox"
      :class="`drop-area ${dragOver ? 'drag' : ''}`"
      @dragover.prevent
      @dragover="onDragOver"
      @dragleave="onDragLeave"
      @drop="onFileDrop"
      @click="triggerFileInputClick"
      :style="previewUrl && `background-image: url(${previewUrl});`"
    >
      <p v-if="!selectedFile" style="color: #5A5A5A; display: flex; align-items: center;"><img src="/svgs/plus.svg" style="height: 1.25rem; margin-right: .35rem"> Upload image.</p>
      <p v-else style="color: #fff"> Edit Image</p>
    </div>
    <input
      type="file"
      style="display: none;"
      ref="fileInput"
      @change="onFileSelected"
    />
    <!-- <button @click="uploadFile" :disabled="!selectedFile">Upload</button> -->
  </div>
</template>

<script>
import { BACKEND_URL } from "@/services/config";
console.log('the url is', BACKEND_URL)
import { axiosInstance } from '@/services/auth-service';

export default {
  data() {
    return {
      selectedFile: null,
      dragOver: false,
      previewUrl: ''
    };
  },
  props: {
    thumbnail: String,
  },
  watch: {
    thumbnail(newValue) {
      if(newValue && (newValue.indexOf('cdn') > -1 || newValue.indexOf('cloudfront') > -1))
        this.previewUrl = newValue;
    },
  },
  methods: {
    triggerFileInputClick() {
      this.$refs.fileInput.click()
    },

    onDragOver() {
      console.log('the url is', BACKEND_URL)
      this.dragOver = true;
    },

    onDragLeave() {
      this.dragOver = false;
    },

    onFileSelected(event) {
      // Get the selected file from the input event
      this.selectedFile = event.target.files[0];
    },
    async onFileDrop(event) {
      event.preventDefault();
      this.selectedFile = event.dataTransfer.files[0];
      // this.$emit('select', this.selectedFile)
      let res = await this.uploadFile();
      this.$emit('select', res.filePath);
      this.$emit('select-v2', {
        filePath: res.filePath, 
        thumbnail: res.thumbnail || res.filePath,
        mediaType: res.mediaType
      })

      // console.log('setting preview url', `${BACKEND_URL}/${res.thumbnail.split('/')[1]}`)
      if(res.thumbnail)
        this.previewUrl = `${BACKEND_URL}/${res.thumbnail.split('/')[1]}`;
      else
        this.previewUrl = `${BACKEND_URL}/${res.filePath.split('/')[1]}`;
    },
    async uploadFile() {
      if (!this.selectedFile) {
        alert("Please select a file first.");
        return;
      }

      // Your file upload logic here using fetch API
      const formData = new FormData();
      formData.append("file", this.selectedFile);

      // Assuming you have an endpoint to handle the file upload on the server
      let res;
      try {
        res = await axiosInstance.post(`${BACKEND_URL}/file-upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Important to set the correct content type
          },
        })
        // res = await fetch(`${BACKEND_URL}/file-upload`, {
        //   method: "POST",
        //   body: formData,
        // });
        // console.log(res.data);

        // if(!res.ok)
        //   throw new Error('network response was not ok!');

        // res = await res.json();
        return res.data;
      } catch(err) {
        console.error("Error uploading file:", err);
      }
    },
  },
};
</script>

<style>
.file-upload-container {
  text-align: center;
}

.drop-area {
  /*border: 2px dashed #ccc;*/
  background-color: #EBEBEB;
  /*padding: .5rem;*/
  height: 14rem;
  cursor: pointer;
  transition: .2s ease background-color;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

.drag {
  background-color: #d9d9d9;
}
</style>