<template>
    <Head>
        <link href="https://cdnjs.cloudflare.com/ajax/libs/video.js/7.14.3/video-js.css" rel="stylesheet">
        <link href="https://unpkg.com/@silvermine/videojs-quality-selector/dist/css/quality-selector.css" rel="stylesheet">
    </Head>
    <div>
        <Nav />
        <div class="container">
            <br /><br />
            <!-- to fix margin / padding issue, put in flex wrapper -->
            <div style="display: flex; flex-direction: 1;">
                <div class="card" style="flex: 1;">
                    <div class="card-body" style="margin: 2rem 2.5rem">
                        <h1 v-if="!channelId">Create a Channel</h1>
                        <h1 v-else>Edit Channel</h1>
                    </div>
                </div>
            </div>
    
            <br />
            <div style="display: flex">
            <div class="card" style="flex: 1">
                <div class="card-body">
                    <Form 
                        :data="data"
                        :currentPage="currentPage"
                        :submittable="submittable"
                        @next="next"
                        @submit="submit"
                    />
                </div>
            </div>
            </div>

            <br />

            <div style="display: flex;" v-if="channelId">
                <div class="card" style="width: 49%; min-height: 500px; margin-right: 1rem;">
                    <div class="card-body">
                        <h2>Sessions</h2>

                        <table style="width: 100%; text-align: left;">
                            <thead>
                                <th>Stream ID</th>
                                <th>Start time</th>
                                <th>Duration</th>
                            </thead>
                            <tbody>
                                <tr 
                                    v-for="(session, key) in sessions"
                                    :key="key"
                                >
                                    <td>{{ session.streamId }}</td>
                                    <td>{{ session.startTime }}</td>
                                    <td>{{ millisecondsToTime(new Date(session.endTime).getTime() - (new Date(session.startTime).getTime())) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="video-container">
                    <video 
                        ref="channel1" 
                        id="ivselem" 
                        data-test="ivselasdmtest" 
                        class="video-js vjs-4-3 vjs-big-play-centered"
                        data-setup='{"fluid": true}' 
                        height="300px" 
                        controls autoplay playsinline
                    ></video>
                </div>
            </div>

            <br />
        </div>
    </div>
</template>
<style>
.video-container {
    flex: 1
}
</style>
<script>
function millisecondsToTime(duration) {
    let seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
}
</script>
<script setup>

import { useRoute } from 'vue-router';
import router from '@/router';
import Nav from '@/components/Nav';
import { ref, onMounted } from 'vue';
import videojs from 'video.js';
// import FileDrop from './FileDrop';
import Form from '@/components/Form.vue';
import { create, getChannel } from '@/services/channels-service';

const currentPage = ref(0);
const submittable = ref(true);

const channel1 = ref(null);
const sessions = ref(null);

const route = useRoute();
const channelId = route.params.channelId;

/**
 * paired defaults to true
 * if paired defaults to true, if false it does not connect for labels
 */

const data = ref([
    {
        title: 'General Info',
        flex: true,
        inputs: [
            {
                name: 'name',
                required: true,
                label: 'Name',
                type: 'input',
                placeholder: 'Title',
                value: '',
                paired: false
            },
            {
                name: 'url',
                required: false,
                label: 'Url',
                type: 'input',
                placeholder: 'url',
                value: '',
                paired: false,
                disabled: true
            },
            {
                name: 'ingest_endpoint',
                required: false,
                label: 'Ingest Endpoint',
                value: '',
                type: 'input',
                paired: false,
                disabled: true
            },
            {
                name: 'stream_key',
                required: false,
                label: 'Stream Key',
                value: '',
                type: 'input',
                paired: false,
                disabled: true
            }
        ]   
    }
])

const next = () => {
    currentPage.value++;
}

const submit = async () => {
    let payload = {
        ...channelId ? { id: channelId } : {}
    };
    submittable.value = true;
    data.value.forEach(block => {
        block.inputs.forEach(input => {
            if(!input.name) return;
            if(input.required && !input.value) return submittable.value = false;
            if(input.type === 'dropdown') payload[input.name] = input.value.id;
            else payload[input.name] = input.value;
        })
    })
    if(!submittable.value) return;

    try {
        await create(payload);
    } catch(err) {
        alert('Error submitting channel.')
        return console.log(err);
    }
    router.push('/channels');
}

onMounted(async () => {
    if(!channelId) return;
    let res = await getChannel(channelId);
    console.log(res)
    let _channel = res.channel;
    Object.keys(_channel).forEach(key => {
        // data.value[0].inputs.find(i => i.name === 'schoolId').value = res.school;
        // data.value[0].inputs.find(i => i.name === 'sportId').value = res.sport;
        let input = data.value[0].inputs.find(i => i.name === key);
        if(!input) return;
        // console.log('found input', input)
        input.value = _channel[key];
    })


    sessions.value = res.sessions;
    console.log('GOT SESSIONS', res.sessions)
    if(_channel.url) {

        const waitForIVS = () => new Promise((res) => {
            let _interval = setInterval(() => {
                console.log('waiting...', channel1.value)
                if(!channel1.value) return;
                clearInterval(_interval);
                return setTimeout(res, 1000);
            }, 100);
        })

        let player;

        // Define a custom property and its type
        await new Promise((res) => setTimeout(res, 500));
        await waitForIVS();

        console.log('got ivs!', channel1.value)

        const { registerIVSTech, registerIVSQualityPlugin } = await import('amazon-ivs-player');

        try {
            registerIVSTech(videojs, {
                wasmWorker: '/assets/amazon-ivs-wasmworker.min.js',
                wasmBinary: '/assets/amazon-ivs-wasmworker.min.wasm'
            });

            registerIVSQualityPlugin(videojs);
        } catch(err) {
            console.log('got an error registering ivs tech', err);
        }

        ivsInit(channel1.value, _channel.url);


    }
})

function ivsInit(val, url) {
    let player = videojs(val, {
        techOrder: ["AmazonIVS"]
    }, () => {
        player.src(url)
        player.ready(() => {
            player.play();
        });
    });

    let hasBeenCalled = false;

    const retryFunction = (err) => {
        console.log('got error...')
        setTimeout(() => {
            if(hasBeenCalled) return;
            console.log('retrying...')
            player.off('error', retryFunction)
            hasBeenCalled = true;
            ivsInit(val, url)
        }, 6000)
    }

    player.on('error', retryFunction)

}
</script>
<style type="text/css" scoped>

</style>